import {Offre} from '../Offre';

export class Admin {
  admin_id: number;
  name: string;
  email: string;
  mobile: string;
  passwordDecrypt: string;
  responsible: number;
  privilege_id: number;
  valid_date: string = null;
  admin_payment: AdminPayment = new AdminPayment();
  offres: Offre [] = [];
}

export class AdminPayment {
  payment_id: number;
  isPaid: number;
  price: number = 0;
  deadline: Date = null;
}

