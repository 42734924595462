import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models/User';
import { AdminService } from 'src/app/shared/services/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Credentials } from '../../shared/models/admin/credentials';
import { ParticipantService } from 'src/app/shared/services/participant.service';

declare var jQuery: any;

@Component({
  selector: 'app-list-organizers',
  templateUrl: './list-organizers.component.html',
  styleUrls: ['./list-organizers.component.css']
})
export class ListOrganizersComponent implements OnInit {
  busy: Subscription;
  dtOptions: any = {};
  search: string = "";
  perPage: number = 9;
  page: number = 1;
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;
  tri: string;
  order: string;
  organizers: User[] = [];
  user: User = new User();
  link: any;



  constructor(private authService: AuthService, private adminServices: AdminService,private participantService: ParticipantService) {}

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      dom: "['<l>', Bfrtip']",
      buttons: [
        'colvis'
      ],
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      aLengthMenu: [[10, 25, 50, 100000], [10, 25, 50, "All"]],
      ajax: (dataTablesParameters: any, callback) => {
        const page = (dataTablesParameters.start / 10 + 1);
        const perPage = dataTablesParameters.length;
        const search = dataTablesParameters.search.value;
        const tri = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
        const order = dataTablesParameters.order[0].dir;
        that.busy = that.adminServices.getOrganizersPagination(page, perPage, search).subscribe((data: any) => {
          this.organizers = data.data;
          callback({
            recordsTotal: data.to,
            recordsFiltered: data.total,
            data: that.organizers
          });
        },
          (error) => {
          }
        );
      },
      columns: that.getColumns(),
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        jQuery('.logins-as-btn', row).unbind('click');
        jQuery('.logins-as-btn', row).bind('click', (event) => {
          this.showLoginModal(data);
        });
      }
    };
  }


  getColumns() {
    const columns = [

      {
        data: 'first_name',
        visible: true,
        render: function (data, type, row) {
          return row.name;
        }
      },
      {
        data: 'email',
        visible: true,
      },
      {
        data: 'mobile',
        visible: true,
        render: function (data, type, row) {
          return row.mobile ? row.mobile : '-'
        }
      },
      {
        data: 'loginAs',
        visible: true,
        render: function (data, type, row) {
          return '<a href="javascript:void(0)" class="badge bg-info logins-as-btn"><span class="icon-arrow-right14"></span></a>';
        }
      },

    ];
    return columns;
  }

  showLoginModal(data) {
    this.user = data;
    jQuery('#continueModal').modal('show');
  }

  loginAs() {
    this.busy = this.participantService.getToken(this.user.email,true).subscribe((res: any) => {
      this.link = environment.baseUrlOrganizerBackoffice + '/auth/login?token=' + res.token + '&user=' + res.user.email;
      window.open(this.link, "_blank");
      jQuery('#continueModal').modal('hide');
    });
  }

}