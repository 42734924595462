import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from '../shared/models/Project';
import { ProjectService } from '../shared/services/project.service';
import { Utils } from '../shared/utils/utils';

@Component({
  selector: 'app-manage-projects',
  templateUrl: './manage-projects.component.html',
  styleUrls: ['./manage-projects.component.css']
})
export class ManageProjectsComponent implements OnInit {
  busy: Subscription;
  projects: Project[] = [];

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.getAllProject()
  }

  getAllProject() {
    this.busy = this.projectService.getAllProjects().subscribe((data: Project[]) => {
      this.projects = data;
    });
  }

  deleteProject(project_id: number) {
    this.busy = this.projectService.deleteProject(project_id).subscribe((data: Project[]) => {
      this.projects = data
      Utils.sweetAlert('success', 'Succès !!', 'Projet supprimée avec succès');
    });
  }

}
