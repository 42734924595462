<div class="content" [ngBusy]="busy">
    <div class="card">
        <div class="card-header header-elements-inline">
            <h6 class="card-title">Liste des projets</h6>
            <div class="heading-elements">
                <button [routerLink]="['/manage-projects/add']" type="button" class="btn btn-info btn-xs">
                    <i class="icon-plus2 position-left"></i>Ajouter un projet
                </button>
            </div>
        </div>
        <br>
        <div class="card-body">
            <table class="table perso-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nom</th>
                        <th>date</th>
                        <th>lien</th>
                        <th>Nom du client</th>
                        <th>Catégorie</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let project of projects;let i=index;">
                        <td>
                            {{project.project_id}}
                        </td>
                        <td>
                            {{project.nom}}
                        </td>
                        <td>
                            {{project.date}}
                        </td>
                        <td > 
                            {{ project.lien !=null ? project.lien : '-'}} 
                        </td>
                        <td>
                            {{project.admin.name}}
                        </td>
                        <td>
                            {{project.category.label}}
                        </td>
                        <td>
                            <div class="list-icons">
                                <div class="dropdown">
                                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                                        <i class="icon-menu9"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item"
                                            [routerLink]="'/manage-projects/edit/'+project.project_id">
                                            <i class="icon-database-edit2"></i>Modifier
                                        </a>
                                        <a class="dropdown-item" (click)="deleteProject(project.project_id)">
                                            <i class="icon-database-remove"></i> Supprimer
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
