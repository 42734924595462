<div class="content">
  <div class="card">
    <div class="card-header header-elements-inline" [ngBusy]="busy">
      <h6 class="card-title">Liste des Menus</h6>
    </div>
    <div class="card-body">
      <div class="sortable">
        <div *ngFor="let menu of containers ;let i=index" id="{{i}}">
          <div class="row" >
            <div class="form-group col-md-3">
              <a><i class="icon-list"></i></a>
              <label class="control-label" style="margin-left: 5px;"> icône : <i class="{{menu.icon}}"> </i></label>
              <input [(ngModel)]="menu.icon" name="icon{{i}}" type="text" class="form-control" id="demo{{i}}"
                >
            </div>
            <div class="form-group col-md-3">
              <label class="control-label"> key: </label>
              <input [(ngModel)]="menu.key" name="key{{i}}" type="text" class="form-control">
              <label *ngIf="submitted&&!menu.key" class="form-text text-danger">required</label>
            </div>

            <div class="form-group col-md-3">
              <label class="control-label"> url : </label>
              <input [(ngModel)]="menu.url" name="url{{i}}" type="text" class="form-control">
            </div>
            <div class="col-md-3" style="margin-top: 28px;">
              <button class="btn" (click)="showSubItem(i)"><i class="icon-menu9"></i></button>
              <button style="margin-left:10px" class="btn btn-danger" (click)="removeItem(i)">X
              </button>
            </div>
          </div>
          <div class="sortable-{{i}}" *ngIf="visibleIndex === i">
            <div *ngFor="let menuItem of menu.menu_children ;let j=index " id={{j}}
          >
              <div class="group-items " style="margin-left: 80px;">
                <div class="row">
                  <div class="form-group col-md-3">
                    <a><i class="icon-list"></i></a>
                    <label class="control-label" style="margin-left: 5px;"> icône : <i class="{{menuItem.icon}}"></i>
                    </label>
                    <input id="childrenicon{{j}}" [(ngModel)]="menuItem.icon" name="childicon{{j}}" type="text"
                      class="form-control">
                  </div>
                  <div class="form-group col-md-3">
                    <label class="control-label"> key: </label>
                    <input [(ngModel)]="menuItem.key" name="childkey{{j}}" type="text" class="form-control">
                    <label *ngIf="submitted&&!menuItem.key" class="form-text text-danger">required</label>
                  </div>
                  <div class="form-group col-md-3">
                    <label class="control-label"> url : </label>
                    <input [(ngModel)]="menuItem.url" name="childurl{{j}}" type="text" class="form-control">
                    <label *ngIf="submitted&&!menuItem.url" class="form-text text-danger">required</label>
                  </div>
                  <div style="margin-top:28px;">
                  <button  class="btn btn-danger"  (click)="deleteSousMenu(i,j)">X
                  </button>
                  </div>
                </div>
                 
              </div>
            </div>
            <button style="margin-left: 350px;margin-top: 20px;" type="button" class="btn btn-info btn-xs"
              (click)="addSousMenu(i)">
              <i class="icon-plus2 position-left"></i>Ajouter un Sous Menu
            </button>
          </div>
        </div>
      </div>
      <div style=" margin-top: 30px;">
        <button style="margin-left: 300px;" type="button" class="btn btn-info btn-xs" (click)="add()">
          <i class="icon-plus2 position-left"></i>Ajouter un Menu
        </button>
        <button style="margin-left: 10px;" class="btn btn-success" (click)="validerMenu()"><i
            class="icon-checkmark3"></i>Confirmer les Changements
        </button>
      </div>
    </div>
  </div>