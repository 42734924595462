import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { ListUsersComponent } from './list-users/list-users.component';

export const routes: Routes = [
    {
        path: 'list',
        component: ListUsersComponent,
       
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManageUsersRoutingModule {
}
