import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ng-file',
  templateUrl: './ng-file.component.html',
  styleUrls: ['./ng-file.component.css']
})
export class NgFileComponent implements OnInit {
  @Input() file: string;
  @Input() multiple: boolean;
  @Input() resources: string;
  files: string[] = [];
  baseFiles = environment.filesUrlAdminBackOffice;
  @Input() accept: string;
  constructor() { }

  ngOnInit(): void {
    if (this.multiple) {
      this.files.push(this.resources);
    } else {
      this.files.push(this.resources);
    }
  }

}
