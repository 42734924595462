import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AdminService} from '../admin.service';
import {Role} from '../../models/Role';

@Injectable()
export class CanActivateViaAuthSuperAdminAuth implements CanActivate {

    constructor(private adminService: AdminService, private router: Router) {
    }

    canActivate() {
        return true;
    //     if (this.userService.getAuthorities(Role.SUPER_ADMIN)) {
    //         return true;
    //     }
    //     this.router.navigate(['/']);
    //     return false;
    }

}
