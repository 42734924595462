<!-- Members online -->
<div class="card">
    <div class="card-body">
        <div style="margin-bottom: 10px">
            {{options.title}}
        </div>
        <div class="row">
            <div class="col-md-6">
                <h3 class="font-weight-bold mb-0"
                    style="color: #4D4F5C">{{ bigValue }}</h3>
                <span style="font-size: 100%"
                      class="font-weight-bold align-self-center ml-auto {{options.positive ? 'text-success' : 'text-danger'}}"><i
                        class="{{options.positive ? 'icon-stats-growth2 text-success'  : 'icon-stats-decline2 text-danger'}}"></i> {{options.percentage.toFixed(2)}}
                    %</span>
            </div>
            <div class="col-md-6">
                <div id="{{chart.idName}}"></div>
            </div>
        </div>
    </div>

</div>
<!-- /members online -->
