/**
 * Created by Abbes on 15/06/2017.
 */
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AdminService} from '../admin.service';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {

    constructor(private adminService: AdminService, private router: Router) {
    }

    canActivate() {
         return true;
       /* if (this.userService.isLoggedIn()) {
            return true;
        }
        this.router.navigate(['/auth/login']);
        return false;*/
    }

}
