<!-- Form horizontal -->
<div class="card">
    <div class="card-header header-elements-inline">
        <h5 class="card-title">{{ !isEditMode ? 'Ajouter offre' : 'Modifier offre'}}</h5>
        <!-- <div class="heading-elements">
            <div class="list-icons">
              <a class="list-icons-item" data-action="collapse"></a>
              <a class="list-icons-item" data-action="reload"></a>
              <a class="list-icons-item" data-action="remove"></a>
            </div>
          </div> -->
    </div>
    <div class="card-body">
        <form class="form-horizontal" action="#" [ngBusy]="busy">
            <div class="row">
                <div class="form-group col-md-6">
                    <label class="control-label">Nom : </label>
                    <input [(ngModel)]="offre.name" name="nom" type="text" class="form-control">
                    <label *ngIf="submitted&&!offre.name" class="form-text text-danger">required</label>
                </div>

                <div class="form-group col-md-6">
                    <label class="control-label">Nom de l'admin: </label>
                    <app-ng-select2 [(selection)]="offre.admin_id" [defaultValue]="'Choisir un admin'"
                        [objects]="clients" [stringOption]="'name'" [idOption]="'admin_id'">
                    </app-ng-select2>
                    <label *ngIf="submitted && !offre.admin_id" class="form-text text-danger">required</label>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-md-6">
                    <label>Date de début </label>
                    <app-ng-datepicker [(selection)]="offre.start_date" [withTime]="false">
                    </app-ng-datepicker>
                    <label *ngIf="submitted&&!offre.start_date" class="form-text text-danger">
                        required
                    </label>
                </div>
                <div class="form-group col-md-6">
                    <label>Date de fin </label>
                    <app-ng-datepicker [(selection)]="offre.end_date" [withTime]="false">
                    </app-ng-datepicker>
                    <label *ngIf="submitted&&!offre.end_date" class="form-text text-danger">
                        required
                    </label>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-md-6">
                    <label class="control-label">Type de l'offre: </label>
                    <app-ng-select2 [(selection)]="offre.offre_type_id" [defaultValue]="'Choisir un type'"
                        [objects]="types" [stringOption]="'name'" [idOption]="'offre_type_id'">
                    </app-ng-select2>
                    <label *ngIf="submitted && !offre.offre_type_id" class="form-text text-danger">required</label>
                </div>


                <div *ngIf="offre.offre_type_id === '1' || offre.offre_type_id === '4'" class="form-group col-md-6">
                    <label class="control-label">Prix : </label>
                    <input [(ngModel)]="offre.value" name="value" type="number" class="form-control">
                    <label *ngIf="submitted&&!offre.value" class="form-text text-danger">required</label>
                </div>

                <div *ngIf="offre.offre_type_id === '2' || offre.offre_type_id === '3'" class="form-group col-md-6">
                    <label class="control-label">Commission par client en DT: </label>
                    <input [(ngModel)]="offre.value" name="value" type="number" class="form-control">
                    <label *ngIf="submitted&&!offre.value" class="form-text text-danger">required</label>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-6">
                    <app-ng-checkbox [(selection)]="offre.is_mail_pro"
                        [label]="' Voulez-vous intégrer un module d\'emailing professionelle ?'">
                    </app-ng-checkbox>
                </div>
            </div>

            <div class="row text-center" *ngIf="selectedMenus.length === 0">
                <div class="col-md-2">
                    <button (click)="addMenu()" style="margin-top:3px;width: 100%"
                        class="btn btn-success"> Ajouter des menus
                    </button>
                </div>
            </div>

            <div *ngFor="let menuChild of selectedMenus;let i=index"
                style="margin-bottom: 20px; padding: 10px 10px 20px 20px;">

                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">Droits dans les menus </label>
                        <app-ng-select2 [(selection)]="menuChild.menu_id" [defaultValue]="'Choisir un menu'"
                            [objects]="menus" [stringOption]="'key'" [idOption]="'menu_id'"
                            (selectionChange)="setChildren(menuChild.menu_id, i)">
                        </app-ng-select2>
                    </div>
                    <div class="col-md-2" *ngIf="menuChild.menu_id">
                        <app-ng-checkbox *ngIf="menuChild.menu_children.length!==0"
                            [(selection)]="menuChild.showMenuChildren" [basicStyle]="true"
                            [label]="'Choisir les sous menus'"></app-ng-checkbox>
                    </div>
                    <div class="col-md-2">
                        <label class="control-label">Action: </label>
                        <button (click)="deleteMenu(i)" style="width: 100%" class="btn btn-danger">
                            Supprimer
                        </button>
                        <button (click)="addMenu()" *ngIf="i===selectedMenus.length-1"
                            style="margin-top:3px;width: 100%" class="btn btn-success"> Ajouter
                        </button>
                    </div>
                </div>
                <div class="row" *ngIf="menuChild.showMenuChildren">
                    <div class="col-md-6">
                        <label class="control-label">Droits dans les sous menus </label>
                        <app-ng-select2 [(selection)]="menuChild.menu_children_ids"
                            [defaultValue]="'Choisir un sous menu'" [objects]="menuChild.menu_children"
                            [stringOption]="'key'" [idOption]="'menu_children_id'" [multiple]="true">
                        </app-ng-select2>
                    </div>
                </div>
            </div>

            <br>
            <div class="row text-center">
                <button type="submit" (click)="submitOffre()" class="btn btn-primary">
                    {{ !isEditMode ? 'Ajouter offre' : 'Modifier offre'}}
                    <i class="icon-arrow-right14 position-right"></i></button>
            </div>

        </form>
    </div>
</div>