<!-- Content area -->
<div class="content">
  <!-- Invoice archive -->
  <div class="card">

    <div class="card-header header-elements-inline" [ngBusy]="busy">
      <h6 _ngcontent-oso-c62="" class="card-title">Liste des organisateurs</h6>
    </div>
    <div class="card-body">

      <table id="organizersTable" cellspacing="0" width="100%" datatable [dtOptions]="dtOptions"
        class="table organizers-table list-organizers-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Login As</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
  </div>
  <!-- /invoice archive -->
</div>
<div class="modal fade" id="continueModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Continuer vers</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 ml-3">
            <label class="control-label font-16">Organizer Back office</label>
            <input name="options" ng-control="options" class="ml-1" type="radio" value="OrganizerBackoffice" />
      </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary logins-as-btn" (click)="loginAs()">Continuer</button>
      </div>
    </div>
  </div>
</div>