import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListOffreComponent} from './list-offre/list-offre.component';
import {AddOffreComponent} from './add-offre/add-offre.component';


const routes: Routes = [
    {
        path: 'list',
        component: ListOffreComponent
    },
    {
        path: 'add-offre/:admin_id',
        component: AddOffreComponent
    },
    {
        path: 'edit-offre/:offre_id',
        component: AddOffreComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManageOffreRoutingModule {
}
