import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {Admin} from '../models/admin/admin';
import {HttpClient} from '@angular/common/http';
import {StorageService} from './storage.service';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class CustomerService extends GenericService {
    loggedAdmin: Admin;

    constructor(private http: HttpClient, private storageService: StorageService) {
        super();
        this.loggedAdmin = <Admin>storageService.read(environment.userKey);
    }

    getAll() {
        const url = environment.baseUrlApi + '/customer';

        return this.http.get(url)
            .pipe(catchError(this.handleErrors));
    }

}
