<!-- Content area -->

<div class="content">
  <!-- Invoice archive -->
  <div class="card">
    <div class="card-header header-elements-inline" [ngBusy]="busy">
      <h6 class="card-title">Liste des clients</h6>

      <div class="heading-elements">
        <button
          [routerLink]="['/clients/add-client']"
          type="button"
          class="btn btn-info btn-xs"
        >
          <i class="icon-plus2 position-left"></i>Ajouter un client
        </button>
      </div>
    </div>
    <br />

    <div class="card-body">
      <table class="table perso-table" datatable [dtOptions]="dtOptions">
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Offre</th>
            <th>Status de paiement</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
  <!-- /invoice archive -->
</div>
