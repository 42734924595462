import {NgModule} from '@angular/core';

import {ManageMailRoutingModule} from './manage-mail-routing.module';
import {MailEditorComponent} from './mail-editor/mail-editor.component';
import {FormsModule} from "@angular/forms";
import {ListMailComponent} from './list-mail/list-mail.component';
import {SharedModule} from "../shared/shared.module";
import { QuillModule } from 'ngx-quill'
import * as Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

@NgModule({
  imports: [
    SharedModule,
    ManageMailRoutingModule,
    QuillModule.forRoot()
  ],
  declarations: [MailEditorComponent, ListMailComponent]
})
export class ManageMailModule {
}
