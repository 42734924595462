export class DatatablesUtils {
  static renderActionsBtn() {
    const actions = this.getActionsAdmin();

    return (
      `<div class="list-icons">
                  <div class="dropdown">
                    <a href="#" class="list-icons-item" data-toggle="dropdown">
                      <i class="icon-menu9"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">` +
      actions +
      `     </div>
               </div>
              </div>`
    );
  }
  static renderPaymentStatusBtn(isPaid) {
    const status = this.getPaymentStatus();
    var color: string;
    var actualStatus: string;
    var icon: string;
    switch (isPaid) {
      case 1: {
        color = "bg-green";
        actualStatus = "Paied";
        icon = "icon-checkmark3";
        break;
      }
      case 0: {
        color = "bg-grey";
        actualStatus = "On Hold";
        icon = "icon-warning";
        break;
      }
      case -1: {
        color = "bg-warning";
        actualStatus = "Not Paied";
        icon = "icon-diff-removed";
        break;
      }
    }

    return (
      `<div class="btn-group dropdown"><a  href="javascript:" class="badge ` +
      color +
      ` align-top dropdown-toggle"
        data-toggle="dropdown">
         <i class="` +
      icon +
      `"></i>` +
      actualStatus +
      `
     </a></div><div class=" dropdown-menu dropdown-menu-right">

` +
      status +
      `</div>`
    );
  }

  private static getActionsAdmin() {
    const config = [
      {
        id: 1,
        title: "Modifier",
        icon: "icon-database-edit2",
        visible: true,
      },
      {
        id: 2,
        title: "Supprimer",
        icon: "icon-box-remove",
        visible: true,
      },
      {
        id: 3,
        title: "Envoyer un Email",
        icon: "icon-envelope",
        visible: true,
      },
      {
        id: 4,
        title: "Ajouter Offre",
        icon: "icon-add",
        visible: true,
      },
    ];
    let result = ``;
    for (let i = 0; i < config.length; i++) {
      if (config[i].visible) {
        result +=
          `<a href='javascript:void(0)' action-id="` +
          config[i].id +
          `" class="dropdown-item action-btn" ><i class="` +
          config[i].icon +
          `"></i>` +
          config[i].title +
          `</a>`;
      }
    }
    return result;
  }

  private static getPaymentStatus() {
    const config = [
      {
        id: 5,
        title: "Paied",
        icon: "icon-checkmark3",
        visible: true,
      },
      {
        id: 6,
        title: "On Hold",
        icon: "icon-warning",
        visible: true,
      },
      {
        id: 7,
        title: "Not Paied",
        icon: "icon-diff-removed",
        visible: true,
      }
    ];
    let result = ``;
    for (let i = 0; i < config.length; i++) {
      if (config[i].visible) {
        result +=
          `<a class="dropdown-item action-btn" href='javascript:void(0)' action-id="` +
          config[i].id +
          `" ><i class="` +
          config[i].icon +
          `"></i>` +
          config[i].title +
          `</a>`;
      }
    }
    return result;
  }
}
