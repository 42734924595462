/**
 * Created by Abbes on 30/06/2017.
 */
import { OffreType } from '../models/Offre';
import { Menu, MenuChildren } from '../models/Menu';
import { environment } from 'src/environments/environment';
import { TranslateService } from "@ngx-translate/core";
import { combineLatest } from 'rxjs';

declare var swal: any;
declare var jQuery: any;

export class Utils {

    static getOffreType(): OffreType[] {
        return [
            {
                offre_type_id: 1,
                name: 'Forfait'
            },
            {
                offre_type_id: 2,
                name: 'Commission sur le nombre des inscrits'
            },
            {
                offre_type_id: 3,
                name: 'Commission sur le nombre des présents'
            },
            {
                offre_type_id: 4,
                name: 'Abonnement '
            }
        ];
    }

    public static zero(n: number) {
        if (n < 10) {
            return '0' + n;
        }
        return n;
    }

    static randomPasswordGenerate(max, min) {
        const passwordChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#@!%&()/';
        const randPwLen = Math.floor(Math.random() * (max - min + 1)) + min;
        const randPassword = Array(randPwLen).fill(passwordChars).map(function (x) {
            return x[Math.floor(Math.random() * x.length)];
        }).join('');
        return randPassword;
    }

    private static groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    static setCheckBoxValue(className: string, value) {
        setTimeout(function () {
            if (value) {
                jQuery('.' + className).prop('checked', true);
            } else {
                jQuery('.' + className).prop('checked', false);
            }
            jQuery.uniform.update();
        }, 5);

    }

    static initSimpleDatePicker(className: string, timePicker: boolean) {
        jQuery('.' + className).daterangepicker({
            'singleDatePicker': true,
            'timePicker': timePicker,
            'timePicker24Hour': true,
            'timePickerIncrement': 15,
            'locale': {
                'format': 'DD/MM/YYYY'
            }
        });
    }

    static sweetAlert(type: string, title: string, text: string) {
        swal(
            title,
            text,
            type);
    }

    public static groupByM(array, f) {
        let groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    }


    static initializeDataTables(className, timout: number, columnNumber: number = 0, orderBy: number = 0) {
        // Basic datatable
        if (jQuery.fn.DataTable.isDataTable('.' + className)) {
            jQuery('.' + className).dataTable().fnDestroy();
        }
        setTimeout(function () {
            const tableListStation = jQuery('.' + className);
            // Setting datatable defaults
            jQuery.extend(jQuery.fn.dataTable.defaults, {
                autoWidth: false,
                columnDefs: [{
                    orderable: false,
                    width: '100px',
                    targets: [columnNumber - 1]
                }],
                dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
                language: {
                    search: '<span>Filter:</span> _INPUT_',
                    lengthMenu: '<span>Show:</span> _MENU_',
                    paginate: { 'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;' }
                },
                drawCallback: function () {
                    jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
                },
                preDrawCallback: function () {
                    jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
                }
            });


            // Basic datatable
            tableListStation.DataTable({
                dom: 'Bfrtip',
                order: [orderBy, 'asc'],
                language: {
                    'emptyTable': '...'
                },
                buttons: {
                    dom: {
                        button: {
                            className: 'btn btn-default'
                        }
                    },
                    buttons: [
                        'excelHtml5',
                        'pdfHtml5'
                    ]
                }

                ,
                columnDefs: [{
                    targets: [columnNumber - 1],
                    orderable: false,
                    width: '50px',
                }]
            });

            // Add placeholder to the datatable filter option
            jQuery('.dataTables_filter input[type=search]').attr('placeholder', 'Type to filter...');


            // Enable Select2 select for the length option
            jQuery('.dataTables_length select').select2({
                minimumResultsForSearch: Infinity,
                width: 'auto'
            });
        }
            , timout);


    }

    static convertDate(date: string) {
        if (date) {
            const HH = date.substring(11, 13);
            const mm = date.substring(14, 16);
            return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4) + (HH && mm ? ' ' + HH + ':' + mm : '');
        }
        return null;
    }

    static convertTime(time: string) {
        if (time) {
            return time.substring(0, 5);
        }
        return null;
    }

    static convertRealDate(date: string, time?: string) {
        if (date) {
            console.log(date);

            console.log(date.substring(0, 2), date.substring(3, 5), date.substring(6, 10));
            if (!time) {
                return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1), +date.substring(0, 2));
            } else {
                return new Date(+date.substring(6, 10), (+date.substring(3, 5) - 1),
                    +date.substring(0, 2), +time.substring(0, 2), +time.substring(3, 5));
            }
        }
        return null;
    }

    static getModalTemplate() {
        return '<div class="modal-dialog modal-lg" role="document">\n' +
            '  <div class="modal-content">\n' +
            '    <div class="modal-header">\n' +
            '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
            '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
            '    </div>\n' +
            '    <div class="modal-body">\n' +
            '      <div class="floating-buttons btn-group"></div>\n' +
            '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>\n';
    }

    static getPreviewZoomButtonClasses() {
        return {
            toggleheader: 'btn btn-default btn-icon btn-xs btn-header-toggle',
            fullscreen: 'btn btn-default btn-icon btn-xs',
            borderless: 'btn btn-default btn-icon btn-xs',
            close: 'btn btn-default btn-icon btn-xs'
        };
    }

    static getPreviewZoomButtonIcons() {
        return {
            prev: '<i class="icon-arrow-left32"></i>',
            next: '<i class="icon-arrow-right32"></i>',
            toggleheader: '<i class="icon-menu-open"></i>',
            fullscreen: '<i class="icon-screen-full"></i>',
            borderless: '<i class="icon-alignment-unalign"></i>',
            close: '<i class="icon-cross3"></i>'
        };
    }

    static modalTemplate() {
        return '<div class="modal-dialog modal-lg" role="document">\n' +
            '  <div class="modal-content">\n' +
            '    <div class="modal-header">\n' +
            '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
            '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
            '    </div>\n' +
            '    <div class="modal-body">\n' +
            '      <div class="floating-buttons btn-group"></div>\n' +
            '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>\n';
    }

    static getFileActionSettings() {
        return {
            removeIcon: '<i class="icon-bin"></i>',
            removeClass: 'btn btn-link btn-xs btn-icon',
            uploadIcon: '<i class="icon-upload"></i>',
            uploadClass: 'btn btn-link btn-xs btn-icon',
            zoomIcon: '<i class="icon-zoomin3"></i>',
            zoomClass: 'btn btn-link btn-xs btn-icon',
            downloadIcon: '<i class="icon-download"></i>',
            downloadClass: 'btn btn-link btn-xs btn-icon',
            indicatorNew: '<i class="icon-file-plus text-slate"></i>',
            indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
            indicatorError: '<i class="icon-cross2 text-danger"></i>',
            indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
        };
    }

    static initializeBasicUploadFile(url: string, token: string, className: string, allowedFileExtensions?: string[]) {


        jQuery('.' + className).fileinput({
            uploadUrl: url, // server upload action
            uploadAsync: true,
            browseLabel: 'Browse',
            showPreview: true,
            browseIcon: '<i class="icon-file-plus"></i>',
            uploadIcon: '<i class="icon-file-upload2"></i>',
            removeIcon: '<i class="icon-cross3"></i>',
            layoutTemplates: {
                icon: '<i class="icon-file-check"></i>',
                modal: Utils.modalTemplate()
            },
            allowedFileExtensions: allowedFileExtensions,
            initialCaption: 'No file selected',
            previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
            previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
            fileActionSettings: Utils.getFileActionSettings(),
            ajaxSettings: { headers: { 'Authorization': 'Bearer ' + token } }
        });
    }

    static initializeUploadFile(url: string, token: string, className: string, maxFileCount?: number, initialData?: any[],
        initialPreviewConfig?: InitialPreviewConfig[], allowedFileExtensions?: string[]) {
jQuery(className).fileinput({
uploadUrl: url, // server upload action
uploadAsync: true,
allowedFileExtensions: allowedFileExtensions,
maxFileCount: maxFileCount,
showUpload: true,
showRemove: true,
initialPreview: initialData,
overwriteInitial: false,
initialPreviewAsData: true,
initialPreviewFileType: 'image', // image is the default and can be overridden in config below
initialPreviewConfig: initialPreviewConfig,
dropZoneTitle: "Pas encore de fichier(s) selectionné(s)",
fileActionSettings: {
removeIcon: '<i class="icon-bin"></i>',
removeClass: 'btn btn-link btn-xs btn-icon',
uploadIcon: '<i class="icon-upload"></i>',
uploadClass: 'btn btn-link btn-xs btn-icon',
zoomIcon: '<i class="icon-zoomin3"></i>',
zoomClass: 'btn btn-link btn-xs btn-icon',
downloadIcon: '<i class="icon-download"></i>',
downloadClass: 'btn btn-link btn-xs btn-icon',
indicatorNew: '<i class="icon-file-plus text-slate"></i>',
indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
indicatorError: '<i class="icon-cross2 text-danger"></i>',
indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
},
purifyHtml: true, // this by default purifies HTML data for preview
initialCaption: "Pas encore de fichier selectionné",
previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}},
deleteUrl: url,
}).on("filebatchselected", function (event, files) {
jQuery(className).fileinput("upload");
});
}

    static convertDateServer(date: string) {
        const HH = date.substring(11, 13);
        const mm = date.substring(14, 16);
        return date.substring(6, 10) + '-' + date.substring(3, 5) + '-' + date.substring(0, 2) + (HH && mm ? ' ' + HH + ':' + mm : '');
    }

    static loadTypeFromExtension(ext: string) {
        if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
            return 'video';
        }
        if (ext.toLowerCase().match(/(pdf)$/i)) {
            return 'pdf';
        }
    }

    static loadFileTypeFromExtension(ext: string) {
        if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
            return 'video/' + ext;
        }
        if (ext.toLowerCase().match(/(pdf)$/i)) {
            return 'pdf';
        }
    }

    static getYears(minYear: number) {
        const years: number[] = [];

        for (let i = minYear; i <= new Date().getFullYear(); i++) {
            years.push(i);
        }
        return years;
    }

    static getUniversityYears(minYear: number) {
        const uniYears: string[] = [];

        for (let i = minYear; i <= new Date().getFullYear(); i++) {
            let j = i + 1;
            uniYears.push(i + '-' + j);
        }

        return uniYears;
    }

    static verifyNewStudent(study_access_year: string) {
        if (!study_access_year) {
            return false;
        }
        const year = parseInt(study_access_year.substring(0, 4));
        return year === new Date().getFullYear();
    }

    static getStatusSection(validations: any[], id_section: number) {

        if (!validations) {
            return null;
        }
        const result = jQuery.grep(validations, function (e) {
            return e.id_section === id_section;
        });


        if (!result || result.length === 0) {
            return null;
        } else {
            return result[0];
        }
    }


    static initSelect(className: string, isSearch?: boolean) {
        const select = jQuery('.' + className);
        select.select2();
        /*if (isSearch === false) {
            select.select2({
                minimumResultsForSearch: Infinity
            });
        } else {

        }*/

    }

    static setValuesjQueryCmp(className: string, values: any, timout: number) {

        if (values) {
            setTimeout(function () {
                const component = jQuery('.' + className);
                component.val(values).trigger('change');
            }, timout);

        }
    }

    static getCurrentUniversityYear(cycle: number) {
        // cycle => 1 : PDCEM , 2 : IRESIDANT
        let fullYear = new Date().getFullYear();
        if (cycle === 1) {
            if (new Date().getMonth() >= 0 && new Date().getMonth() <= 5) {
                fullYear -= 1;
            }
        } else {
            if (new Date().getMonth() >= 0 && new Date().getMonth() <= 8) {
                fullYear -= 1;
            }
        }
        return (fullYear) + '-' + (fullYear + 1);
    }

    static getLabelNiveau(niveau_id: number) {
        switch (niveau_id) {
            case 1:
                return 'PCEM1';
            case 2:
                return 'PCEM2';
            case 3:
                return 'DCEM1';
            case 4:
                return 'DCEM2';
            case 5:
                return 'DCEM3';
            case 6:
                return 'DCEM4';
            case 7:
                return 'DCEM4 (AR)';
            case 8:
                return 'TCEM1';
            case 9:
                return 'TCEM2';
            case 10:
                return 'TCEM3';
            case 11:
                return 'TCEM4';
            case 12:
                return 'TCEM5';

        }
    }


    static getLevelIdFromName(niveau_name: any) {
        switch (niveau_name) {
            case 'PCEM1':
                return 1;
            case 'PCEM2':
                return 2;
            case 'DCEM1':
                return 3;
            case 'DCEM2':
                return 4;
            case 'DCEM3':
                return 5;
            case 'DCEM4':
                return 6;
            case 'DCEM4(AR)':
                return 7;
            case 'TCEM1':
                return 8;
            case 'TCEM2':
                return 9;
            case 'TCEM3':
                return 10;
            case 'TCEM4':
                return 11;
            case 'TCEM5':
                return 12;
        }
    }

    static getResultIdFromName(result_name: any) {
        switch (result_name) {
            case 'Redoublant':
                return 1;
            case 'Admis':
                return 2;
            case 'Admis avec crédit':
                return 3;
            case 'admis et orienté':
                return 5;
        }
    }

    static getResultNameFromId(result_id: number) {
        switch (result_id) {
            case 1:
                return 'Redoublant';
            case 2:
                return 'Admis';
            case 3:
                return 'Admis avec crédit';
            case 5:
                return 'admis et orienté';
        }
    }


    static setValueToRadioBox(className: string, value: any, timout: number) {
        const radioBox = jQuery('.' + className).prop('checked', false);
        jQuery.uniform.update(radioBox);
        if (value !== undefined) {
            setTimeout(function () {
                const radioBox = jQuery('.' + className + '[value=' + value + ']').prop('checked', true);
                jQuery.uniform.update(radioBox);
            }, timout);
        }
    }

    static initDatePicker(className: string, timePicker: boolean) {
        jQuery('.' + className).daterangepicker({
            timePicker: timePicker,
            timePicker24Hour: true,
            singleDatePicker: true,
            showDropdowns: true,
            locale: {
                format: 'DD/MM/YYYY HH:mm'
            }
        });

    }

    static getRandomString() {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    static getRandomStringWithNumbers(length: number) {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    static getIdentifiantStudent(student: any) {
        if (!student.cin && !student.passport) {
            return '-';
        }
        return student.cin ? student.cin.code : student.passport.code;
    }

    static initSliderMenu(direction: string, className: string, timout: number) {
        setTimeout(function () {
            const oppositeDirection = direction === 'right' ? 'left' : 'right';
            jQuery('#' + className).slideReveal({
                position: direction,
                trigger: jQuery('#' + className + ' .handle'),
                push: false,
                shown: function (obj) {
                    obj.find('.handle').html('<span class="glyphicon glyphicon-chevron-' + direction + '"></span>');
                },
                hidden: function (obj) {
                    obj.find('.handle').html('<span class="glyphicon glyphicon-chevron-' + oppositeDirection + '"></span>');
                }
            });
        }, timout);
    }


    static getRandomInRange(from, to, fixed) {
        return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
        // .toFixed() returns string, so ' * 1' is a trick to convert to number
    }

    static initCheckBox(className: string) {
        jQuery('.' + className).uniform({ radioClass: 'choice' });
    }


    static getAllRoles() {
        return ['ROLE_CLIENT', 'ROLE_SUPER_ADMIN'];
    }

    public static hexToRgb(hex) {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;

        return [b, g, r];
    }

    static colorCodeToRGB(colorCode: string, opacity: number) {
        const codeWithoutD = colorCode.substring(1);
        const rgbArray = Utils.hexToRgb(codeWithoutD);
        return 'rgb(' + rgbArray[2] + ',' + rgbArray[1] + ',' + rgbArray[0] + ',' + opacity + ')';
    }

    static convertToBigValue(n, dr) {
        if (n < 1000) {
            return n + '';
        }
        let x = ('' + n).length, p = Math.pow, d = p(10, dr);
        x -= x % 3;
        return Math.round(n * d / p(10, x)) / d + ' kMGTPE'[x / 3];
    }

    static initMenus(menus: Menu[]) {
        let result = menus;
        for (let i = 0; i < result.length; i++) {
            result[i].menu_children_ids = [];
            result[i].menu_children_ids = result[i].menu_children.map(child => {
                return child.menu_children_id;
            });
        }
        return result;
    }
    static initSortableElements(className: string, timout = 0) {
        setTimeout(function () {
            jQuery('.' + className).sortable();
            jQuery('.' + className).disableSelection();
        }, timout);
    }
    static sortArrayByPositions(array: any[], authorPositions: any[]) {
        if (authorPositions.length === 0) {
            return array;
        }
        let res = [];
        authorPositions.forEach(
            (pos, i) => {
                let parsedPos = parseInt(pos, 0);
                if (!isNaN(parsedPos)) {
                    array[parsedPos].index = i + 1;
                    res.push(array[parsedPos]);
                }
            }
        );
        return res;
    }
    

    public static sortMenusByIndex(menus: any[]) {
        menus.sort(function(a, b) {
          return a.index > b.index ? 1 : -1;
        });
        return menus;
      }
      static getInitialPreviewConfig(paths: string[]) {

        const initialPreviewConfig: InitialPreviewConfig[] = [];
        const url = environment.baseUrlApi + '/files/delete-resource/';
    
        paths.forEach((path, index) => {
          initialPreviewConfig.push({
            type: Utils.loadTypeFromExtension(path.substr(path.indexOf('.') + 1)),
            filetype: Utils.loadFileTypeFromExtension(path.substr(path.indexOf('.') + 1)),
            key: Utils.getRandomString(),
            url: url + path.substring(path.lastIndexOf('/')+1),
            downloadUrl: path
          });
          //affichage du nom dans le preview
          if (path.indexOf('resource') > -1) {
            initialPreviewConfig[index]['caption'] = path.substr(path.indexOf(')') + 1);
          }
        });
        return initialPreviewConfig;
      }
      static initFileInput(className: string) {
        setTimeout(function () {
          jQuery('.' + className).uniform({
            fileButtonClass: 'action btn bg-green-400'
          });
        }, 100);
      }
      static initCroppie(id, file, path = null) {
        let uploadCrop = jQuery('#' + id).croppie({
          enableExif: true,
          viewport: {
            width: 100,
            height: 100,
            type: 'circle'
          },
          boundary: {
            width: 120,
            height: 120
          }
        });
        if (!path) {
          const fr = new FileReader();
          fr.onload = function (e) {
            setTimeout(function () {
              uploadCrop.croppie('bind', {url: fr.result});
            }, 50)
          };
          fr.readAsDataURL(file);
        } else {
          const myPath = environment.filesUrl + path;
          setTimeout(function () {
            uploadCrop.croppie('bind', {url: myPath});
          }, 50)
        }
        return uploadCrop;
      }

      static renderLoginButton() {
        return `<button action-id="1" type="button" class="btn btn-info  action-btn">Login as</button>`

      }
      
    static swalMessageWithCallback(key: string, message: string, type: string, params: any, transalteService: TranslateService, callback) {
        combineLatest(
            transalteService.get(key),
            transalteService.get(message)
        ).subscribe((res) => {
            let object = Object.assign({
                title: res[0],
                text: res[1],
                icon: type
            }, params);
            swal(object).then(
                function (isConfirm) {
                    callback(isConfirm);
                }
            );
        });
    }
}



export class InitialPreviewConfig {
    caption?: string;
    size?: number;
    width?: string;
    type?: string;
    filetype?: string;
    url: string;
    key: string;
    downloadUrl?: string;
}

