import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { ManageMenuComponent } from './manage-menu.component';

export const routes: Routes = [
    {
        path: '',
        component: ManageMenuComponent
    },  
    {
        path: '1',
        component: ManageMenuComponent
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ManageMenuRoutingModule {
}