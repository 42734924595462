import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { HttpClient} from "@angular/common/http";
import { AdminService } from "./admin.service";
import { environment } from "../../../environments/environment";


@Injectable()
export class ParticipantService extends GenericService {

  constructor(private http: HttpClient, private adminService: AdminService) {
    super();
  }

  getParticipantsPagination(congress_id, search: string, page: number, perPage: number, tri: string, order: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.adminService.getToken());
    const url = environment.baseUrlApi + "/all-users/listUsers?congress_id="+congress_id+ "&page=" + page + "&perPage=" + perPage + "&search=" + search + "&tri=" + tri + "&order=" + order;
   
    return this.http.get(url, {
      headers: headers
    });  
  }

  login(email, password) {
    const url = environment.baseUrlApi + '/auth/login/user';
    return this.http.post(url, {email, password});
  }

  getToken(email, isAdmin = null) {
    const url = environment.baseUrlApi + '/auth/get-token?email=' + email + '&isAdmin=' + isAdmin;
    return this.http.get(url);
  }
}
 