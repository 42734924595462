import { Injectable } from "@angular/core";
import { GenericService } from "./generic.service";
import { HttpClient } from "@angular/common/http";
import { AdminService } from "./admin.service";
import { environment } from "../../../environments/environment";
import { StorageService } from "./storage.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class CongressService extends GenericService {


  constructor(private http: HttpClient, private adminService: AdminService, private storageService: StorageService) {
    super();
  }

  getAllCongressesPublic(search: string, page: number, perPage:number) {
    const url = environment.baseUrlApi + `/congress/list/pagination?page=${page}&perPage=${perPage}&search=${search}`;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }
}