<!-- Form horizontal -->
<div class="card">
    <div class="card-header header-elements-inline">
        <h5  class="card-title">{{ !isEditMode ? 'Add Client' : 'Edit Client'}}</h5>
        <!-- <div class="heading-elements">
            <div class="list-icons">
              <a class="list-icons-item" data-action="collapse"></a>
              <a class="list-icons-item" data-action="reload"></a>
              <a class="list-icons-item" data-action="remove"></a>
            </div>
          </div> -->
    </div>
    <div class="card-body">
        <form class="form-horizontal" action="#" [ngBusy]="busy">
            <div class="row">
                <div class="form-group col-md-4">
                    <label class="control-label">Nom : </label>
                    <input [(ngModel)]="admin.name" name="nom" type="text" class="form-control">
                    <label *ngIf="submitted&&!admin.name" class="form-text text-danger">required</label>
                </div>

                <div class="form-group col-md-4">
                    <label class="control-label">Email : </label>
                    <input [(ngModel)]="admin.email" [disabled]="isEditMode" name="email" type="text" class="form-control">
                    <label *ngIf="submitted&&!admin.email" class="form-text text-danger">required</label>
                </div>

                <div class="form-group col-md-4">
                    <label class="control-label">Mobile : </label>
                    <input [(ngModel)]="admin.mobile" name="phone" type="text" class="form-control">
                    <label *ngIf="submitted&&!admin.mobile" class="form-text text-danger">required</label>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12">
                    <label class="control-label">Password</label>
                    <input [(ngModel)]="admin.passwordDecrypt" [disabled]="isEditMode" name="password" type="text" class="form-control">
                    <label *ngIf="submitted&&!admin.passwordDecrypt"
                           class="form-text text-danger">required</label>
                    <button (click)="generatePassword()" [disabled]="isEditMode" type="button" class="btn btn-primary mt-2" id="password"
                            style="float:right;">
                        <i class="fa fa-at"></i>
                        Generate Password
                    </button>

                </div>
            </div>

            <div class="col-md-6">
                <app-ng-checkbox
                        [label]="'Le compte actif dans une période limitée'" [(selection)]="checked">
                </app-ng-checkbox>
            </div>

            <div class="col-md-6" *ngIf="checked">
                <label>Date début</label>
                <app-ng-datepicker [(selection)]="admin.valid_date" [withTime]="false"></app-ng-datepicker>
            </div>
            <br>
            <div class="row text-center">
                <button type="submit" (click)="submitClient()" class="btn btn-primary">
                    {{ !isEditMode ? 'Add Client' : 'Edit Client'}}
                    <i class="icon-arrow-right14 position-right"></i></button>
            </div>

        </form>
    </div>
</div>
