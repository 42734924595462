
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';

import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { AdminService } from "./admin.service";
import { LandingPage } from '../models/LandingPage';


@Injectable()
export class LandingPageService extends GenericService {
    constructor(private http: HttpClient,private adminService: AdminService) {
        super();
        
    }
    getAllDemandes() {
        const headers = this.headers.set("Authorization", "Bearer " + this.adminService.getToken());
        return this.http.get(environment.baseUrlApi +'/request-landing-page/list', {
            headers: headers
        });
    }
    getLandingPageById(request_landing_page_id: number) {
        const url = environment.baseUrlApi + "/request-landing-page/LandingPage" +request_landing_page_id;
  
        return this.http.get(url);
      }

    upadteStatusLandingPage(request_landing_page_id,status) {
        const url = environment.baseUrlApi + '/request-landing-page/' +request_landing_page_id;
        return this.http.put(url, {status: status});
    }
}