<!-- Content area -->
<div class="content">

  <!-- Error title -->
  <div class="text-center content-group">
    <h1 class="error-title">403</h1>
    <h5>Oups, une erreur est survenue. L'accès est interdit !</h5>
  </div>
  <!-- /error title -->
  
  <!-- Error content -->
  <div class="row">
    <div class="col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3">
      <form action="#" class="main-search">

        <div class="row">
          <div class="col-sm-12">
            <a href="/#/" class="btn btn-primary btn-block content-group"><i
              class="icon-circle-left2 position-left"></i> Accéder au dashboard</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- /error wrapper -->
</div>
<!-- /content area -->
