import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LandingPage } from 'src/app/shared/models/LandingPage';
import { Utils } from '../../shared/utils/utils';

import { environment } from 'src/environments/environment';
import { LandingPageService } from 'src/app/shared/services/landing-page.service';
import { TranslateService } from '@ngx-translate/core';
declare let swal: any;
@Component({
  selector: 'app-landing-page-list',
  templateUrl: './landing-page-list.component.html',
  styleUrls: ['./landing-page-list.component.css']
})
export class LandingPageListComponent implements OnInit {
  busy: Subscription;
  landingPages: LandingPage[] = [];
  urlFrontOffice = environment.baseUrlFront;

  constructor(private landingPageservice: LandingPageService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getAllDemandes();
  }
  getAllDemandes() {
    this.busy = this.landingPageservice.getAllDemandes()
      .subscribe(
        (data: any[]) => {
          this.landingPages = data;
          Utils.initializeDataTables('landing-table', 20, 6);
        },
        (error) => {
          swal('Erreur', 'Une erruer s\'est produite!!', 'error');
        }
      );

  }
  upadteStatusLandingPage(request_landing_page_id, status, i) {
    const baseContext = this;
    const params = {
      buttons: true,
      dangerMode: true
    }
    Utils.swalMessageWithCallback('Sure', 'ReplyRequest', 'warning', params, this.translate, function (going) {
      if (going) {
        baseContext.busy = baseContext.landingPageservice.upadteStatusLandingPage(request_landing_page_id, status).subscribe((res: any) => {
          swal('Succès', 'Status de demande changé avec succès', 'success');
          baseContext.landingPages[i].status = res.status;
        },
          (error) => {
            swal('Erreur', 'Une erruer s\'est produite!!', 'error');
          }
        );
      }
    });
  }

  navigateToLandingPageFront(congressId) {
    window.open(this.urlFrontOffice + '/landingpage/' + congressId, '_blank');
  }

}
