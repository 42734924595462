import { Component, OnInit, ViewChild } from "@angular/core";
import { Utils } from "src/app/shared/utils/utils";
import { Subscription } from "rxjs";
import { Admin } from "src/app/shared/models/admin/admin";
import { AdminService } from "src/app/shared/services/admin.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { DatatablesUtils } from "src/app/shared/datatable-utils";

declare let swal: any;

@Component({
  selector: "app-list-client",
  templateUrl: "./list-client.component.html",
  styleUrls: ["./list-client.component.css"],
})
export class ListClientComponent implements OnInit {
  clients: Admin[] = [];
  busy: Subscription;
  dtOptions: any = {};
  perPage: number = 9;
  page: number = 1;
  dtElement: DataTableDirective;

  constructor(public adminService: AdminService, private router: Router) { }

  ngOnInit() {
    const that = this;
    this.dtOptions = {
      dom: "['<l>', Bfrtip']",
      buttons: ["colvis"],
      pagingType: "full_numbers",
      pageLength: 10,
      serverSide: true,
      aLengthMenu: [
        [5, 10, 25, 50, 100000],
        [5, 10, 25, 50, "All"],
      ],
      ajax: (dataTablesParameters: any, callback) => {
        const page = (dataTablesParameters.start / dataTablesParameters.length + 1);
        const perPage = dataTablesParameters.length;
        const search = dataTablesParameters.search.value;

        that.busy = that.adminService
          .getListClients(perPage, page, search)
          .subscribe(
            (data: any) => {
              this.clients = data.data;

              callback({
                recordsTotal: data.to,
                recordsFiltered: data.total,
                data: that.clients,
              });
            },
            (error) => { }
          );
      },
      columns: that.getColumns(),
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        jQuery(".action-btn", row).unbind("click");
        jQuery(".action-btn", row).bind("click", (event) => {
          const actionId = parseInt($(event.target).attr("action-id"), 0);
          this.goToAction(actionId, data);
        });
      },
    };
  }
  getColumns() {
    const columns = [
      {
        data: "admin_id",
        visible: true,
      },
      {
        data: "name",
        visible: true,
        render: function (data, type, row) {
          return row.name;
        },
      },
      {
        data: "email",
        visible: true,
      },
      {
        data: "mobile",
        visible: true,
        render: function (data, type, row) {
          return row.mobile ? row.mobile : "-";
        },
      },
      {
        data: "offres",
        visible: true,
        render: function (data, type, row) {
          if (row.offres.length != 0) {
            return (
              '<span class="badge bg-green">' + row.offres[0].name + "</span>"
            );
          } else {
            return '<span class="badge bg-green">Aucun</span>';
          }
        },
      },
      {
        data: "payment_status",
        visible: true,
        render: function (data, type, row) {

          if (row.admin_payment.length == 0) {
            return '<span class="badge bg-green">Aucun</span>';
          } else if (row.admin_payment[0].isPaid != null && row.admin_payment.length != 0) {
            return DatatablesUtils.renderPaymentStatusBtn(
              row.admin_payment[0].isPaid
            );
          }
        },
      },

      {
        data: "actions",
        visible: true,
        render: function (data, type, row) {
          return DatatablesUtils.renderActionsBtn();
        },
      },
    ];
    return columns;
  }

  private goToAction(actionId: number, data) {
    switch (actionId) {
      case 1: {
        this.router.navigate(["/clients/" + data.admin_id + "/edit-client"]);
        break;
      }
      case 2: {
        this.removeClient(data.admin_id);
        break;
      }
      case 3: {
        this.sendCredentialsViaEmailToClient(data.admin_id);
        break;
      }
      case 4: {
        this.router.navigate(["/manage-offre/add-offre/" + data.admin_id]);
        break;
      }

    }
  }

  sendCredentialsViaEmailToClient(admin_id) {
    this.busy = this.adminService.sendCredentialsViaEmailToClient(admin_id)
    .subscribe(
        (data) => {
            Utils.sweetAlert('success', 'Succéss', 'Un email contenant un mot de passe sera envoyé au client pour accéder à son espace Eventizer');
        },
        (error) => {
                  Utils.sweetAlert('error', 'Erreur', 'Erreur est survenu');
        }
    );
      }  removeClient(admin_id) {
    this.busy = this.adminService.removeClientByid(admin_id).subscribe(
      (data) => {
        Utils.sweetAlert('success', 'Succées', 'Suppression client avec succées');
        let that = this;
        that.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.search("").draw();
        });

      }, error => {
        Utils.sweetAlert('error', 'Erreur', 'Erreur lors de la suppression');
      });
  }
}