import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../shared/services/storage.service';
import {AdminService} from '../shared/services/admin.service';
import {Admin} from '../shared/models/admin/admin';
import {LoadPageService} from '../shared/services/load-page.service';
import {NavigationMain} from '../shared/models/menu/navigation-main';
import { AuthService } from '../shared/services/auth.service';

declare let jQuery: any;

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.css']
})
export class FullLayoutComponent implements OnInit, AfterViewInit {

    admin: Admin;

    title: string;
    year:number;

    components: NavigationMain[] = [];

    constructor(private storageService: StorageService,
                public router: Router, private adminService: AdminService,
                private route: ActivatedRoute,
                private loadPageService: LoadPageService,
                private authService:AuthService) {

        this.admin = this.adminService.loggedAdmin;

        if (!this.admin) {
            this.admin = new Admin();
        }
        /*this.userService
            .getCurrentClient()
            .subscribe(
                (data: Client) => {
                    this.userService.storeUser(data);
                    this.user = data;
                },
                (error) => {

                }
            );*/

        /*this.route.queryParams.subscribe(
            params => {
                console.log(params.reload);
                if (params.reload) {
                    window.location.href = '/';
                }
            }
        );*/
    }

    ngOnInit() {

        this.components = [
            {
                name: 'Dashboard',
                icon: 'icon-stats-bars2',
                url: '/',
                title: 'Overview'
            },
            {
                name: 'Gestion clients',
                icon: 'icon-select2',
                childrens: [
                    {
                        name: 'List des clients',
                        url: '/clients/list'
                    }
                ]
            },
            {
                name: 'Gestion organizateurs',
                icon: 'icon-user-tie',
                childrens: [
                    {
                        name: 'List des organizateurs',
                        url: '/clients/list-organizers'
                    }
                ]
            },
            {       
                name: 'Gestion des demandes',
                icon: 'icon-select2',
                childrens: [
                    {
                        name: 'List des demandes',
                        url: '/clients/list-landing-page'
                    }
                ]
            },
            {
                name: 'Emailing automatique',
                icon: 'icon-envelop',
                // hidden: !this.adminService.checkIfAdminHasRole(1),
                childrens: [
                    {
                    name: 'Configuration et saisie',
                    url: '/manage-mail/list'
                    }
                ]
            },
            {
                name: 'Gestion des offres',
                icon: 'icon-list',
                childrens: [
                    {
                        name: 'Tous les offres',
                        url: '/manage-offre/list'
                    }
                ]
            },
            {
                name: 'Gestion des Menus',
                icon: 'icon-menu',
                childrens: [
                    {
                        name: 'Menus Event',
                        url: '/manage-menu'
                    },
                    {
                        name: 'Menus hors event',
                        url: '/manage-menu/1'
                    }
                ]
            },
            {
                name: 'Gestion des projets',
                icon: 'icon-list',
                childrens: [
                    {
                        name: 'Tous les projets',
                        url: '/manage-projects/list'
                    }
                ]
            },
            {
                name: 'Gestion des utilisateurs',
                icon: 'icon-user',
                childrens: [
                    {
                        name: 'Liste des utilisateurs',
                        url: '/manage-users/list'
                    }
                ]
            },
            {
                name: 'Configuration',
                icon: 'icon-list',
                childrens: [
                    {
                        name: 'Attestations',
                        url: '/manage-configuration/list-attestations'
                    }
                ]
            },
            
        ];

        this.changeActiveUrl(this.router.url);


        this.loadPageService.initBeforeLoad();
        const d = new Date();
        this.year = d.getFullYear();
    }

    ngAfterViewInit(): void {
        this.loadPageService.initAfterLoad();
    }

    changeActiveUrl(url: string) {
        this.components.forEach(
            component => {
                component.active = '';
                if (url === component.url) {
                    component.active = 'active';
                    this.title = component.title;
                }
                if (component.childrens) {
                    component.childrens.forEach(
                        child => {
                            child.active = '';
                            if (url.indexOf(child.url) !== -1) {
                                child.active = 'active';
                            }
                        }
                    );
                }
            }
        );
    }

    goUrl(url: string) {
        if (url) {
            this.router.navigate([url]);
            this.changeActiveUrl(url);
        }
    }

    logout() {
        this.authService.logout().subscribe(
            (data) => {
            },
            error => {
            }
          );
        this.storageService.removeAll();
        this.router.navigateByUrl('/auth/login');
    }

}



