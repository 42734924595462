
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    userKey: 'client',
    userTokenKey: 'user-token',
    baseUrlApi: 'https://feat.api.eventizer.io/api',
    baseUrlFront: 'https://admin.eventizer.io',
    filesUrl: 'https://eventizer-dev.fra1.cdn.digitaloceanspaces.com/',
    baseUrlFrontoffice: "https://feat.app.eventizer.io/#",
    baseUrlOrganizerBackoffice: "https://feat.organizer.eventizer.io/#",
    baseUrlPWA: "https://dev.participant.eventizer.io/#",
    filesUrlAdminBackOffice: 'https://eventizer-prod.s3.eu-west-3.amazonaws.com/',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
