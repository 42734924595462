<div class="row center-form">
    <div class="col-md-8 offset-2">
        <div class="some-espace">
            <div class="title vayetek-color-primary">
                Eventizer Admin </div>
            <div class="description">
                {{'LoginText' | translate}}
            </div>
        </div>
        <div [ngBusy]="busy">
            <form ngForm=#loginForm role="form" [formGroup]="loginForm" (ngSubmit)="loginSubmit()" class="example-form"
                style="margin: auto">
                    <label for="email" class="control-label"></label>
                    <input [(ngModel)]="credentials.email" placeholder="Email" type="text" name="email"
                        class="form-control email" id="email" formControlName="email" />
                    <label for="password" class="control-label"></label>
                    <input [(ngModel)]="credentials.password" class="form-control"
                        placeholder="{{'Password' | translate}}" type="password" id="password" name="password"
                        formControlName="password" />
                    <div class="row some-espace">
                        <div class="col-sm-6 text-left mt-2 mb-2">
                            <div class="form-check edge">
                                <input type="checkbox" class="form-check-input" id="rememberMe">
                                <label class="form-check-label" for="rememberMe">{{'RememberLogin'|translate}}</label>
                            </div>
                        </div>
                        <button type="submit" (click)="loginSubmit()" class="btn btn-block edge btn-info"
                            [disabled]="!loginForm.valid">{{'Login'|translate}} </button>
                    </div>
                </form>
        </div>
    </div>
</div>