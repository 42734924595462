import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListUsersComponent } from './list-users/list-users.component';
import { SharedModule } from '../shared/shared.module';
import { ManageUsersRoutingModule } from './manage-users.routing.module';
import { ParticipantService } from '../shared/services/participant.service';
import { DataTablesModule } from 'angular-datatables';
import { CongressService } from '../shared/services/congress.service';



@NgModule({
  declarations: [ListUsersComponent],
  imports: [
    CommonModule,
    SharedModule,
    ManageUsersRoutingModule,
    DataTablesModule
  ],
  providers: [
    ParticipantService,
    CongressService
  ]
})
export class ManageUsersModule { }
