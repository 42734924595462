<div class="card" [ngBusy]="busy">
  <div class="card-header header-elements-inline">
    <h5 class="card-title"> Ajouter un projet</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 form-group">
        <label>Nom </label>
        <input id="name" type="text" name="name" class="form-control" [(ngModel)]="project.nom" #nom="ngModel">
        <span class="form-text text-danger" *ngIf="(submitted&&!project.nom)"> required
        </span>
      </div>
      <div class="col-md-6 form-group">
        <label>Date </label>
        <app-ng-datepicker [(selection)]="project.date" [withTime]="false"></app-ng-datepicker>
        <span class="form-text text-danger" *ngIf="(submitted&&!project.date)"> required
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label>Nom du Client</label>
        <app-ng-select2 [(selection)]="project.admin_id" [defaultValue]="'Choisir un admin'" [objects]="clients"
          [stringOption]="'name'" [idOption]="'admin_id'">
        </app-ng-select2>
        <span class="form-text text-danger" *ngIf="(submitted&&!project.admin_id)"> required
        </span>
      </div>
      <div class="col-md-6 form-group">
        <label>lien </label>
        <input id="lien" type="text" name="lien" class="form-control" [(ngModel)]="project.lien">
      </div>
    </div>
    <div class="row">
      <div class="col-md-5 form-group">
        <label>Catégorie</label>
        <app-ng-select2 [defaultValue]="'Choisir une catégorie'" [multiple]="false" [idOption]="'category_id'"
          [stringOption]="'label'" [objects]="categories" [(selection)]="project.category_id">
        </app-ng-select2>
        <span class="form-text text-danger" *ngIf="(submitted&&!project.category_id)"> required
        </span>
      </div>
      <div class="mt-2">
        <button (click)="showAdd()" class="btn btn-info btn-sm pull-right mt-3">
          Ajouter catégorie
        </button>
      </div>
    </div>
    <div class="row mt-2" *ngIf="showAddCategory">
      <div class="form-group col-md-4">
        <label>
          Label
        </label>
        <input [(ngModel)]="category.label" name="label" type="text" class="form-control">
      </div>
      <div class="mt-4">
        <button (click)="addCategory()" class="btn btn-success btn-sm">Ajouter</button>
      </div>
    </div>
    <br>
    <div class="form-group col-lg-6 col-md-6" *ngIf="ready || !isEdit">
      <label class="control-label col-md-12"> Bannière du projet: 445*296 </label>
      <form action="#" class="form-horizontal">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group ">
                  <div class="file-loading">
                    <app-upload-file [maxFileCount]="1" [uploadType]="'file-input'" [previewFiles]="pathsBanner"
                      (onUpload)="onUploadImg($event)" (onDelete)="onDeleteImg($event)" [accept]="'image/*'">
                    </app-upload-file>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-10">
      <button class="btn btn-primary mt-4" style="position: relative; float: right; left: 10rem; bottom: 2rem;"
        (click)="addProject()">
        {{ (isEdit ? 'Modifier' :'Ajouter') | translate}}
      </button>
    </div>
  </div>
</div>