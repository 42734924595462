<!-- Content area -->
<div class="content">
    <!-- Invoice archive -->
    <div class="card">
        <div class="card-header header-elements-inline" [ngBusy]="busy">
            <h6 class="card-title">Liste des offres</h6>

            <div class="heading-elements">
            </div>

        </div>
        <br>

        <div class="card-body">
            <table class="table offre-table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Nom</th>
                    <th>Client</th>
                    <th>Type</th>
                    <th>Prix</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let offre of offres;let i=index;">
                    <td>
                        {{offre.offre_id}}
                    </td>
                    <td>
                        {{offre.name}}
                    </td>
                    <td>
                        {{offre.admin.name}}
                    </td>
                    <td>
                        {{offre.type.name}}
                    </td>
                    <td>
                        {{offre.value}}
                    </td>
                    <td>
                        {{offre.start_date}}
                    </td>
                    <td>
                        {{offre.end_date}}
                    </td>
                    <td>
                        <span class="bg badge label  {{offre.status===1 ? 'bg-success' : 'bg-warning'}}">{{offre.status===1 ? 'Actif':  'Inactif'}}</span>
                    </td>
                    <td>
                        <div class="list-icons">
                            <div class="dropdown">
                                <a href="#" class="list-icons-item" data-toggle="dropdown">
                                    <i class="icon-menu9"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" [routerLink]="['/manage-offre/edit-offre/'+offre.offre_id]"><i
                                            class="icon-database-edit2"></i>Modifier</a>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- /invoice archive -->

</div>
<!-- /content area -->
