import { Subscription } from 'rxjs';
import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AdminService} from '../../shared/services/admin.service';
import {Credentials} from '../../shared/models/admin/credentials';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Admin} from 'src/app/shared/models/admin/admin';
import {environment} from 'src/environments/environment';
import {AuthService} from 'src/app/shared/services/auth.service';
import {StorageService} from 'src/app/shared/services/storage.service';

declare let swal: any;
declare let jQuery: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['login.component.less'],
})
export class LoginComponent implements OnInit {


    @Input()
    credentials: Credentials;
    loginForm: FormGroup;
    baseUrl = environment.baseUrlApi;
    admin: Admin = new Admin();
    selectedCongressId: number = null;
    busy: Subscription;

    ngOnInit() {
        this.loginForm = new FormGroup(
            {
                email: new FormControl('', [Validators.required, Validators.email]),
                password: new FormControl('', [Validators.required, Validators.minLength(6)])
            }
        );
    }

    constructor(private adminService: AdminService,
                private authService: AuthService,
                private router: Router,
                private route: ActivatedRoute,
                private stoarageService: StorageService) {
        this.credentials = new Credentials();
    }

    loginSubmit() {
        this.credentials.password = this.credentials.password.split(' ').join('');
        this.busy = this.authService.login(this.credentials).subscribe(data => {
                if ((data.admin.privilege_id === 9) || (data.admin.privilege_id === 10)) {
                    this.admin = data.admin;
                    this.stoarageService.write(StorageService.TOKEN_STORAGE, data.token);
                    this.adminService.loggedAdmin = data.admin;
                    this.stoarageService.write(StorageService.ADMIN_STORAGE, data.admin);
                    this.router.navigate(['/']);
                } else {
                    swal('Erreur', 'Email non confirmé ! Une erruer s\'est produite! Veuillez vérifier vos coordonnées!', 'error');
                }
            }, (error) => {
                let message = error.status === 400 ? 'Email non confirmé' : error.status === 401 ? 'Vérifiez que vos données sont correctes!' : 'Une erruer s\'est produite! Veuillez réessayer plus tard!';
                swal('Erreur', message, 'error');
            }
        );
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.loginForm.controls[controlName].hasError(errorName);
    };
}


