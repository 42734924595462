import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {OffreService} from '../../shared/services/offre.service';
import {Offre} from '../../shared/models/Offre';
import {Utils} from '../../shared/utils/utils';

@Component({
  selector: 'app-list-offre',
  templateUrl: './list-offre.component.html',
  styleUrls: ['./list-offre.component.css']
})
export class ListOffreComponent implements OnInit {
  busy: Subscription;
  offres: Offre [] = [];

  constructor(private offreService: OffreService) { }

  ngOnInit(): void {
    this.getAllOffres();
  }

  getAllOffres () {
    this.busy = this.offreService.getAllOffres().subscribe( (data: Offre []) => {
      this.offres = data;
      Utils.initializeDataTables('offre-table', 20, 8);
    });
  }

}
