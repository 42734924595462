<!-- Main navbar -->
<div class="navbar navbar-expand-md navbar-light navbar-static">

    <!-- Header with logos -->
    <div class="navbar-header navbar-dark d-none d-md-flex align-items-md-center">
        <div class="navbar-brand navbar-brand-md">
            <a href="javascript:" class="d-inline-block">
                <img src="../../assets/images/logo/vayetek-white.png" alt="">
            </a>
        </div>
        <div class="navbar-brand navbar-brand-xs">
            <a href="javascript:" class="d-inline-block">
                <img src="../../assets/images/logo/vayetek_icon_white.png" alt="">
            </a>
        </div>
    </div>
    <!-- /header with logos -->


    <!-- Mobile controls -->
    <div class="d-flex flex-1 d-md-none">
        <div class="navbar-brand mr-auto">
            <a href="javascipt:" class="d-inline-block">
                <img src="../../assets/images/logo_dark.png" alt="">
            </a>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
            <i class="icon-tree5"></i>
        </button>

        <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
            <i class="icon-paragraph-justify3"></i>
        </button>
    </div>
    <!-- /mobile controls -->


    <!-- Navbar content -->
    <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a href="#" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
                    <i class="icon-paragraph-justify3"></i>
                </a>
            </li>
        </ul>


        <!--
        <span class="badge bg-pink-400 badge-pill ml-md-3 mr-md-auto">16 orders</span>-->
        <span class="ml-md-3 mr-md-auto"></span>

        <ul class="navbar-nav">
            <!--
            <li class="nav-item dropdown">
                <a href="#" class="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
                    <img src="../../assets/images/lang/gb.png" class="img-flag mr-2" alt="">
                    English
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item english active"><img
                            src="../../assets/images/lang/gb.png" class="img-flag" alt=""> English</a>
                    <a href="#" class="dropdown-item ukrainian"><img src="../../assets/images/lang/ua.png"
                                                                     class="img-flag" alt=""> Українська</a>
                    <a href="#" class="dropdown-item deutsch"><img src="../../assets/images/lang/de.png"
                                                                   class="img-flag" alt=""> Deutsch</a>
                    <a href="#" class="dropdown-item espana"><img src="../../assets/images/lang/es.png"
                                                                  class="img-flag" alt=""> España</a>
                    <a href="#" class="dropdown-item russian"><img src="../../assets/images/lang/ru.png"
                                                                   class="img-flag" alt=""> Русский</a>
                </div>
            </li>
            -->

            <li class="nav-item dropdown">
                <a href="#" class="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
                    <i class="icon-people"></i>
                    <span class="d-md-none ml-2">Users</span>
                    <span class="badge badge-mark border-pink-400 ml-auto ml-md-0"></span>
                </a>

                <div class="dropdown-menu dropdown-content wmin-md-300">
                    <div class="dropdown-content-header">
                        <span class="font-weight-semibold">Users online</span>
                        <a href="#" class="text-default"><i class="icon-search4 font-size-base"></i></a>
                    </div>

                    <div class="dropdown-content-body dropdown-scrollable">
                        <ul class="media-list">
                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <a href="#" class="media-title font-weight-semibold">Jordana Ansley</a>
                                    <span class="d-block text-muted font-size-sm">Lead web developer</span>
                                </div>
                                <div class="ml-3 align-self-center"><span
                                        class="badge badge-mark border-success"></span></div>
                            </li>

                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <a href="#" class="media-title font-weight-semibold">Will Brason</a>
                                    <span class="d-block text-muted font-size-sm">Marketing manager</span>
                                </div>
                                <div class="ml-3 align-self-center"><span class="badge badge-mark border-danger"></span>
                                </div>
                            </li>

                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <a href="#" class="media-title font-weight-semibold">Hanna Walden</a>
                                    <span class="d-block text-muted font-size-sm">Project manager</span>
                                </div>
                                <div class="ml-3 align-self-center"><span
                                        class="badge badge-mark border-success"></span></div>
                            </li>

                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <a href="#" class="media-title font-weight-semibold">Dori Laperriere</a>
                                    <span class="d-block text-muted font-size-sm">Business developer</span>
                                </div>
                                <div class="ml-3 align-self-center"><span
                                        class="badge badge-mark border-warning-300"></span></div>
                            </li>

                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <a href="#" class="media-title font-weight-semibold">Vanessa Aurelius</a>
                                    <span class="d-block text-muted font-size-sm">UX expert</span>
                                </div>
                                <div class="ml-3 align-self-center"><span
                                        class="badge badge-mark border-grey-400"></span></div>
                            </li>
                        </ul>
                    </div>

                    <div class="dropdown-content-footer bg-light">
                        <a href="#" class="text-grey mr-auto">All users</a>
                        <a href="#" class="text-grey"><i class="icon-gear"></i></a>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown">
                <a href="#" class="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
                    <i class="icon-bubbles4"></i>
                    <span class="d-md-none ml-2">Messages</span>
                    <span class="badge badge-mark border-pink-400 ml-auto ml-md-0"></span>
                </a>

                <div class="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
                    <div class="dropdown-content-header">
                        <span class="font-weight-semibold">Messages</span>
                        <a href="#" class="text-default"><i class="icon-compose"></i></a>
                    </div>

                    <div class="dropdown-content-body dropdown-scrollable">
                        <ul class="media-list">
                            <li class="media">
                                <div class="mr-3 position-relative">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>

                                <div class="media-body">
                                    <div class="media-title">
                                        <a href="#">
                                            <span class="font-weight-semibold">James Alexander</span>
                                            <span class="text-muted float-right font-size-sm">04:58</span>
                                        </a>
                                    </div>

                                    <span class="text-muted">who knows, maybe that would be the best thing for me...</span>
                                </div>
                            </li>

                            <li class="media">
                                <div class="mr-3 position-relative">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>

                                <div class="media-body">
                                    <div class="media-title">
                                        <a href="#">
                                            <span class="font-weight-semibold">Margo Baker</span>
                                            <span class="text-muted float-right font-size-sm">12:16</span>
                                        </a>
                                    </div>

                                    <span class="text-muted">That was something he was unable to do because...</span>
                                </div>
                            </li>

                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <div class="media-title">
                                        <a href="#">
                                            <span class="font-weight-semibold">Yassine ABBES</span>
                                            <span class="text-muted float-right font-size-sm">22:48</span>
                                        </a>
                                    </div>

                                    <span class="text-muted">But that would be extremely strained and suspicious...</span>
                                </div>
                            </li>

                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <div class="media-title">
                                        <a href="#">
                                            <span class="font-weight-semibold">Beatrix Diaz</span>
                                            <span class="text-muted float-right font-size-sm">Tue</span>
                                        </a>
                                    </div>

                                    <span class="text-muted">What a strenuous career it is that I've chosen...</span>
                                </div>
                            </li>

                            <li class="media">
                                <div class="mr-3">
                                    <img src="../../assets/images/placeholders/placeholder.jpg" width="36"
                                         height="36" class="rounded-circle" alt="">
                                </div>
                                <div class="media-body">
                                    <div class="media-title">
                                        <a href="#">
                                            <span class="font-weight-semibold">Richard Vango</span>
                                            <span class="text-muted float-right font-size-sm">Mon</span>
                                        </a>
                                    </div>

                                    <span class="text-muted">Other travelling salesmen live a life of luxury...</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="dropdown-content-footer bg-light">
                        <a href="#" class="text-grey mr-auto">All messages</a>
                        <div>
                            <a href="#" class="text-grey" data-popup="tooltip" title="Mark all as read"><i
                                    class="icon-radio-unchecked"></i></a>
                            <a href="#" class="text-grey ml-2" data-popup="tooltip" title="Settings"><i
                                    class="icon-cog3"></i></a>
                        </div>
                    </div>
                </div>
            </li>

            <li class="nav-item dropdown dropdown-user">
                <a href="#" class="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
                    <img src="../../assets/images/placeholders/placeholder.jpg" class="rounded-circle mr-2"
                         height="34" alt="">
                    <span>Yassine</span>
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                    <a href="#" class="dropdown-item"><i class="icon-user-plus"></i> My profile</a>
                    <a href="#" class="dropdown-item"><i class="icon-coins"></i> My balance</a>
                    <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Messages <span
                            class="badge badge-pill bg-indigo-400 ml-auto">58</span></a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item"><i class="icon-cog5"></i> Account settings</a>
                    <a (click)="logout()" href="javascript:" class="dropdown-item"><i class="icon-switch2"></i>
                        Logout</a>
                </div>
            </li>
        </ul>

    </div>
    <!-- /navbar content -->

</div>
<!-- /main navbar -->


<!-- Page content -->
<div class="page-content">

    <!-- Main sidebar -->
    <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md">

        <!-- Sidebar mobile toggler -->
        <div class="sidebar-mobile-toggler text-center">
            <a href="#" class="sidebar-mobile-main-toggle">
                <i class="icon-arrow-left8"></i>
            </a>
            Navigation
            <a href="#" class="sidebar-mobile-expand">
                <i class="icon-screen-full"></i>
                <i class="icon-screen-normal"></i>
            </a>
        </div>
        <!-- /sidebar mobile toggler -->


        <!-- Sidebar content -->
        <div class="sidebar-content">
            <!-- Main navigation -->
            <div class="card card-sidebar-mobile">
                <ul class="nav nav-sidebar" data-nav-type="accordion">
                    <li class="nav-item  {{component.childrens ? 'nav-item-submenu':'' }}"
                        *ngFor="let component of components">
                        <a class="nav-link {{component.active}}" href="#" (click)="goUrl(component.url)"
                           *ngIf="!component.hidden">
                            <i class="{{component.icon}}"></i>
                            <span>{{component.name}}
                        </span>
                            <span *ngIf="component.notification"
                                  class="badge bg-warning-400">{{component.notification}}</span>
                        </a>
                        <ul class="nav nav-group-sub">
                            <li *ngFor="let child of component.childrens"
                                class="{{child.active}} nav-item">
                                <a class="nav-link" href="javascript:" *ngIf="!child.hidden && child.url"
                                   [routerLink]="[child.url]">{{child.name}}
                                    <span *ngIf="child.notification"
                                          class="badge bg-warning-400">{{child.notification}}</span></a>
                            </li>
                        </ul>
                    </li>
                    <!-- /main -->

                </ul>
            </div>
            <!-- /main navigation -->

        </div>
        <!-- /sidebar content -->

    </div>
    <!-- /main sidebar -->


    <!-- Main content -->
    <div class="content-wrapper">
        <!-- Page header -->
        <div class="page-header">
            <div class="page-header-content header-elements-md-inline">
                <div class="page-title d-flex">
                    <h4><span class="font-weight-semibold">{{title}}</span></h4>
                    <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
                </div>
            </div>
        </div>
        <!-- /page header -->

        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <!--Page header -->
        <!--<div class="page-header">-->
        <!--<div class="page-header-content header-elements-md-inline">-->
        <!--<div class="page-title d-flex">-->
        <!--<h4><i class="icon-arrow-left52 mr-2"></i> <span class="font-weight-semibold">Home</span> - -->
        <!--Dashboard</h4>-->
        <!--<a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>-->
        <!--</div>-->

        <!--<div class="header-elements d-none text-center text-md-left mb-3 mb-md-0">-->
        <!--<div class="btn-group">-->
        <!--<button type="button" class="btn bg-indigo-400"><i class="icon-stack2 mr-2"></i> New report-->
        <!--</button>-->
        <!--<button type="button" class="btn bg-indigo-400 dropdown-toggle" data-toggle="dropdown"></button>-->
        <!--<div class="dropdown-menu dropdown-menu-right">-->
        <!--<div class="dropdown-header">Actions</div>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-file-eye"></i> View reports</a>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-file-plus"></i> Edit reports</a>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-file-stats"></i> Statistics</a>-->
        <!--<div class="dropdown-header">Export</div>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-file-pdf"></i> Export to PDF</a>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Export to CSV</a>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--/page header -->


        <!--Content area -->
        <!--<div class="content pt-0">-->
        <!---->
        <!--</div>-->
        <!--/content area -->


        <!-- Footer -->
        <div class="navbar navbar-expand-lg navbar-light fixed-bottom">
            <div class="text-center d-lg-none w-100">
                <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse"
                        data-target="#navbar-footer">
                    <i class="icon-unfold mr-2"></i>
                    Footer
                </button>
            </div>

            <div class="navbar-collapse collapse" id="navbar-footer">
					<span class="navbar-text">
						&copy; {{year}}. <a href="http://www.vayetek.com">Vayetek</a> by <a
                            href="http://www.vayetek.com" target="_blank">VAYETEK</a>
					</span>
                <!--
                <ul class="navbar-nav ml-lg-auto">
                    <li class="nav-item"><a href="https://kopyov.ticksy.com/" class="navbar-nav-link" target="_blank"><i
                            class="icon-lifebuoy mr-2"></i> Support</a></li>
                    <li class="nav-item"><a href="http://demo.interface.club/limitless/docs/" class="navbar-nav-link"
                                            target="_blank"><i class="icon-file-text2 mr-2"></i> Docs</a></li>
                    <li class="nav-item"><a
                            href="https://themeforest.net/item/limitless-responsive-web-application-kit/13080328?ref=kopyov"
                            class="navbar-nav-link font-weight-semibold"><span class="text-pink-400"><i
                            class="icon-cart2 mr-2"></i> Purchase</span></a></li>
                </ul>
                -->
            </div>
        </div>
        <!-- /footer -->

    </div>
    <!-- /main content -->

</div>
<!-- /page content -->


<!--
<div class="navbar-top  pace-done">
  <app-header-layout></app-header-layout>
  &lt;!&ndash; Page container &ndash;&gt;
  <div class="page-container">
    &lt;!&ndash; Page content &ndash;&gt;

    <div class="page-content">
      <div>
        <app-side-bar-right-layout></app-side-bar-right-layout>
        <app-side-bar-layout></app-side-bar-layout>
      </div>
      &lt;!&ndash; Main content &ndash;&gt;
      <div class="content-wrapper">
        &lt;!&ndash; Content area &ndash;&gt;
        <div class="content" style="min-height: 614px">
          <app-chat-layout></app-chat-layout>
          <router-outlet></router-outlet>
        </div>
        &lt;!&ndash; /content area &ndash;&gt;
      </div>
      &lt;!&ndash; /main content &ndash;&gt;
    </div>
    &lt;!&ndash; /page content &ndash;&gt;
  </div>
  &lt;!&ndash; /page container &ndash;&gt;
  <app-footer-layout></app-footer-layout>
  &lt;!&ndash; /page container &ndash;&gt;

</div>
-->
