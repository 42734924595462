<div *ngIf="multiple">
    <select class="form-control {{className}}" multiple>
        <option *ngIf="!multiple" value="0" disabled>{{defaultValue}}</option>
        <option *ngFor="let option of options;let i=index"
                value="{{ids[i]}}">
            {{option | translate}}
        </option>
    </select>
</div>
<div *ngIf="!multiple">
    <select class="form-control {{className}}">
        <option *ngIf="defaultValue" value="0" [disabled]="true">{{defaultValue}}</option>
        <option *ngFor="let option of options;let i=index"
                value="{{ids[i]}}">
            {{option | translate}}
        </option>
    </select>
</div>
